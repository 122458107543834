import axios from 'axios';
import authHeader from './auth-header';

const API_URL = '/api/';

class UserService {
  getUsersBoard(type) {
    let params = type ? {type: type} : null;
    return axios.get(API_URL + 'users', { headers: authHeader() , params: params});
  }

  getModeratorBoard() {
    return axios.get(API_URL + 'mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }
}

export default new UserService();
